<template>
  <div id="main-wrapper">

    <div class="main-content is-footer">


      <!--پرفروشترین ها--->
      <v-container >
        <v-row>
          <v-col >
            <p class="text-center font-medium font-weight-bold pt-3"><v-icon color="black" size="27">mdi-seal</v-icon> تصحیح فرم های موجود در آرشیو </p>
            <p class="text-center font-medium font-weight-bold pt-3" style="border: dashed 1px;background-color: #01feff"> توجه داشته باشید لیست زیر زمانی ظاهر میشود که یک مسابقه حداقل برگزار شده باشد </p>
          </v-col>
        </v-row>


        <v-row justify="center">

          <v-dialog
              v-model="dialog"
              max-width="290"
          >
            <v-card>
              <v-card-title  style="font-family: 'iranyekan',tahoma !important;font-size: medium">
                برای حذف فرم مطمئنید؟
              </v-card-title>

              <v-card-text>
                بعد از حذف فرم  دیگر قابل بازیابی نخواهد بود.
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    color="green darken-1"
                    text
                    @click="dialog = false"
                >
                  خیر
                </v-btn>

                <v-btn
                    color="green darken-1"
                    text
                    @click="Deleteform();"
                >
                  بله
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>



        <vcl-Twitch  v-if="!pageloading" ></vcl-Twitch>
        <vcl-Twitch  v-if="!pageloading" ></vcl-Twitch>
        <v-card v-else :key="key" v-for="(item,key) in form"
                class="mx-auto"
                style="background-color: rgb(255, 255, 135)"

        >
          {{ key+1 }})<span style="font-size: 13px"><b>سریال فرم شما</b> : {{ item.sh_barge }} </span>
          <span style="font-size: 13px"><b>مبلغ فرم </b> : {{ item.mablagh }} </span><br>
          <span style="font-size: 13px"><b>دوره مسابقات </b> : {{ item.dore }} </span> ,  <span style="font-size: 13px"><b>امتیاز فرم </b> : {{ item.emtiaz }} </span><br>

          <div class="flex">
          <v-btn class="flex" @click="$router.push({path:'/formviewarchive/'+item.sh_barge+'_'+$route.params.noe});" :loading="loading" depressed color="#f799d0" height="30" style="width: 100%"> <b>نمایش فرم</b></v-btn>
          <v-btn class="flex" @click="deleteform(item.sh_barge);" :loading="loading" depressed color="#1bffa5" height="30" style="width: 100%"> <b>حذف فرم</b></v-btn>
          </div>
        </v-card>
        <v-divider style="margin-top:5px;"></v-divider>
        <v-card color="#8f08ea" class="flex">
          <v-btn @click="getform(page);" v-if="pageloading" :loading="loading" depressed :color="randomColor()"  height="50" style="width: 47%;margin:5px;"> <b>نمایش بیشتر</b></v-btn>
          <v-btn @click="$router.push({path:'/admin'});" v-if="pageloading" :loading="loading" depressed :color="randomColor()" height="50" style="width: 47%;margin:5px;"> <b>برگشت به صفحه قبل</b></v-btn>
        </v-card>
      </v-container>


    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
// @ is an alias to /src

import {mapGetters} from 'vuex'
import appHeader from '../views/_header'
import appFooter from '../views/_footer'
import { VclFacebook, VclCode,VclTwitch,VclTable } from 'vue-content-loading';
import axios from "axios";


export default {
  name: 'modirarchive',
  components: {
    appHeader,
    appFooter,
    VclFacebook,
    VclCode,
    VclTable,
    VclTwitch,

  },
  data () {
    return {
      show: true,
      url:'http://g.samanyaar.ir/soccerp/',
      form:[],
      loading:false,
      sh_delete:0,
      page:0,
      pageloading: false,
      dialog: false,
    }
  },
  mounted() {
    let self = this;
    self.form=[];
    self.getform(0);

  },
  computed:{
    ...mapGetters(['baseUrl',"auth"]),
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods:{
    deleteform(sh_barge) {

          this.sh_delete=sh_barge;
          this.dialog = true;
         // this.Deleteform(this.sh_delete);
    },

    randomColor() {
      const r = () => Math.floor(400 * Math.random());

      return `rgb(${r()}, ${r()}, ${r()})`;
    },
    Deleteform(){

      let self = this;
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      let formData = new FormData();
      formData.append("token", self.appToken);
      formData.append("code_shakhs", self.$store.getters.auth.data.user_id);
      formData.append("noe", self.$route.params.noe);
      formData.append("sh_barge", self.sh_delete);


      axios.post(this.baseUrl+'/archiveDel.aspx', formData, config)
          .then(function (r) {

            //console.log(r);
            if(r.data.data.result==1){

              self.pageloading=true;
                self.helperToast({
                  show:true,
                  text:"فرم مورد نظر حذف شد",
                  color:'green'
                });
              self.dialog = false;
              self.page=0;
              self.form=[];
              self.getform(0);
            }
            else
            {self.helperToast({
              show:true,
              text:r.data.data.message,
              color:'red'
            });

            }
            self.loading = false;
          });




    },

    getform($page){
      let self = this;
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      let formData = new FormData();
      formData.append("token", self.appToken);
      formData.append("code_shakhs", self.$store.getters.auth.data.user_id);
      formData.append("noe", self.$route.params.noe);
      formData.append("Offset", self.page);
      formData.append("Limit", 10);

      axios.post(this.baseUrl+'/tarchive.aspx', formData, config)
          .then(function (r) {
            //console.log(r.data.data);
            if(r.data.result=="1"){

              var myarr = r.data.data;

              myarr.forEach((value, index) => {
                self.form.push(value);
              });

              self.pageloading=true;
              if (self.form.length==0)
                self.$router.push({path:'/admin'});

             /* if (myarr.length <= 0)
                self.helperToast({
                  show:true,
                  text:"دیگر فرمی وجود ندارد",
                  color:'red'
                });*/
            }
            else
            {self.helperToast({
              show:true,
              text:r.data.data.message,
              color:'red'
            });
              if (self.form.length==0)
              self.$router.push({path:'/admin'});

            }
            self.loading = false;
          });


        self.page=self.page+10;

    },
    handleScroll (event) {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
      if (bottomOfWindow) {
        this.getform(this.page);
      }
    },

  },
  watch:{}
}
</script>

<style>
.border-grid .col{
  height: 100px;
  border-bottom: solid 1px #e3e3e3;
  border-left: solid 1px #e3e3e3;
}
.border-grid .col:nth-child(3n + 0){
  border-left: none;
}
.border-grid .col:nth-last-child(-n + 3){
  border-bottom: none;
}
.border-grid .col p{
  font-weight: 600;
}

.border-grid2 .col{
  height: 300px;
  border-bottom: solid 1px #e3e3e3;
  border-left: solid 1px #e3e3e3;
}
.border-grid2 .col:nth-child(2n + 0){
  border-left: none;
}
.border-grid2 .col:nth-last-child(-n + 2){
  border-bottom: none;
}
.border-grid2 .col p{
  font-weight: 600;
}
.flex {
  display: flex;
}


</style>
